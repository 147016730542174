import React from 'react'
import BillHistoryTemplate from './BillHistoryTemplate'

const index = () => {
  return (
    <>
    <BillHistoryTemplate/>
    </>
  )
}

export default index