import React from 'react'
import BillHistory from '../../view/BillHistory/index'
const index = () => {
  return (
    <>
    <BillHistory/>
    </>
  )
}

export default index