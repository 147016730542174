import React from 'react'
import CustomerBody from './CustomerBody'


const index = () => {
  return (
    <>
    <CustomerBody/>
    </>
  )
}

export default index