import React from 'react'
import SelectResumeStructure from '../../view/SelectResume/SelectResumeStructure'

const index = () => {
  return (
    <>
    <SelectResumeStructure/>
    </>
  )
}

export default index