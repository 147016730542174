import React from 'react'
import WorkHistorySummary from "../../view/WorkHistorySummary/Index";

const Index = () => {
  return (
    <>
      <WorkHistorySummary/>
    </>
  )
}

export default Index