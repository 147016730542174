import React from 'react'
import UpgardeFeature from '../../view/UpgradeFeature/index'
const index = () => {
  return (
    <>
    <UpgardeFeature/>
    </>
  )
}

export default index