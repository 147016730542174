import React from 'react'
import AccessTemplate from './AccessTemplate';

const index = () => {
  return (
    <>
    <AccessTemplate/>
    </>
  );
}

export default index