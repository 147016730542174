import React from 'react'
import ExpScreen from './ExpScreen'

const index = () => {
  return (
    <>
    <ExpScreen/>
    </>
  )
}

export default index