import React from 'react'
import AddSummary from './AddSummary'
const index = ({handleRemoval}) => {
  return (
    <>
    <AddSummary handleRemoval={handleRemoval}/>
    </>
  )
}

export default index