import React from 'react'
import AddSection from './AddSection'

const index = () => {
  return (
    <>
    <AddSection/>
    </>
  )
}

export default index