import React from 'react'
import UpgradeBody from './UpgradeBody'

const index = () => {
  return (
    <>
    <UpgradeBody/>
    </>
  )
}

export default index