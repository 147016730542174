import React from 'react'
import CustomerAccount from '../../view/CustomerAccount/index'
const index = () => {
  return (
    <>
    <CustomerAccount/>
    </>
  )
}

export default index