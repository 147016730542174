import React from 'react';

const Loader = () => {
  return (
    <>
      {/* <div className="loader-box"> */}
      <div className="loader"></div>
      {/* </div> */}
    </>
  );
};

export default Loader;
