import React from 'react'
import ExtraSction from '../../view/ExtraSection/index'
const index = () => {
  return (
    <>
    <ExtraSction/>
    </>
  )
}

export default index