import React from 'react'
import WebPortfolio from "../../view/WebPortfolio/index";
const index = () => {
  return (
    <>
    <WebPortfolio/>
    </>
  )
}

export default index