import React from 'react'
import SkillTemplate from '../../view/SkillTemplate/index'
const index = ({handleRemoval}) => {
  return (
    <>
    <SkillTemplate handleRemoval={handleRemoval}/>
    </>
  )
}

export default index