import React from "react";
import AddAccomplish from "./AddAccomplish";

const index = ({handleRemoval}) => {
  return (
    <>
      <AddAccomplish handleRemoval={handleRemoval}/>
    </>
  );
};

export default index; 
