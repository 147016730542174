import React from 'react'
import AddLanguage from "../../view/AddLanguage/index";
const index = () => {
  return (
    <>
      <AddLanguage />
    </>
  );
}

export default index