import React from 'react'
import AccountBody from './AccountBody'

const index = () => {
  return (
    <>
    <AccountBody/>
    </>
  )
}

export default index