import React from "react";
import AccomplishTemplate from "../../view/AccomplishTemplate/index";

const index = ({handleRemoval}) => {
  return (
    <>
      <AccomplishTemplate  handleRemoval={handleRemoval}/>
    </>
  );
};

export default index;
