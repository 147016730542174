import React from 'react'
import Accessbility from "../../view/Accessibility/index";
const index = () => {
  return (
    <>
    <Accessbility/>
    </>
  )
}

export default index