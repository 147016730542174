import React from "react";
import AddSkill from "./AddSkill";

const index = ({handleRemoval}) => {
  return (
    <>
      <AddSkill handleRemoval={handleRemoval} />
    </>
  );
};

export default index;
