import React from 'react'
import ResumeContactStruture from '../../view/ResumeContactForm/ResumeContactStruture'


const index = () => {
  return (
    <>
      <ResumeContactStruture/>
    </>
  );
};

export default index;
