import React from 'react'
import CustomSectionTwo from '../../view/CustomSectionTwo'
const index = () => {
  return (
    <>
    <CustomSectionTwo/>
    </>
  )
}

export default index