import React from 'react'
import SummaryTemplate from '../../view/SummaryTemplate/index'
const index = ({handleRemoval}) => {
  return (
    <>
    <SummaryTemplate handleRemoval={handleRemoval}/>
    </>
  )
}

export default index