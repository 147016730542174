import React from "react";
import AddCertification from "./AddCertification";

const index = ({handleRemoval}) => {
  return (
    <>
      <AddCertification handleRemoval={handleRemoval}/>
    </>
  );
};

export default index;
