import React from "react";
import AddAffiliations from "./AddAffiliations";

const index = ({handleRemoval}) => {
  return (
    <>
      <AddAffiliations handleRemoval={handleRemoval}/>
    </>
  );
};

export default index;
