import React from 'react'
import WorkSummary from "./WorkSummary";

const Index = () => {
  return (
    <>
    <WorkSummary/>
    </>
  )
}

export default Index