import React from 'react'

import Customer from '../../view/Customer/index'
const index = () => {
  return (
    <>
    <Customer/>
    </>
  )
}

export default index