import React from "react";
import AddDetail from "../../view/AddDetail/index";
const index = () => {
  return (
    <>
      <AddDetail />
    </>
  );
};

export default index;
