import React from 'react'
import ChooseTemplate from '../../view/ChooseTemplate/index'
const index = () => {
  return (
    <>
    <ChooseTemplate/>
    </>
  )
}

export default index