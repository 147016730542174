import React from 'react'
import Portfolio from './Portfolio'
const index = () => {
  return (
    <>
    <Portfolio/>
    </>
  )
}

export default index