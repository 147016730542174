import React from 'react'
import Language from "./Language";
const index = () => {
  return (
    <>
      <Language />
    </>
  );
}

export default index